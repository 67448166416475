import React from "react";
import { Link } from "gatsby";
import "./Header.css";
import pp_log_with_text from "../../images/phonepe-logo-with-text.svg";

export default function Header() {
  return (
    <>
      <div className="phonepeDocNav">
        <header>
          <div className="phonepeDocNav__logo-cont">
            <div className="phonepeDocNav__logo">
              <Link to="/">
                <img src={pp_log_with_text} alt="PhonePe Logo" />
              </Link>
            </div>
          </div>
          <div className="phonepeDocNav__general-faqs-btn" > <Link to='/v1/docs/general-faq' >General FAQs</Link> </div>
        </header>
      </div>
    </>
  )
}


