import React from "react";
import classNames from "classnames";
import "./WidgetContainer.css";

const WidgetContainer = ({ children, stylesClass }) => {
    return (
        <div className={classNames("full-width-container", stylesClass)}>
            <div className="content">{children}</div>
        </div>
    );
};

export default WidgetContainer;

WidgetContainer.defaultProps = {
    stylesClass: "",
};
